import { createMemo, type ParentProps, splitProps } from 'solid-js'
import { Dynamic } from 'solid-js/web'
import { textRecipe, TextVariants } from './text.css'
import { defaultProps } from '@/lib/defaultProps'

type BaseProps = ParentProps &
    TextVariants & {
        class?: string
    }

type PrimaryProps = BaseProps & {
    fontFamily?: 'primary' | 'serif'
    fontWeight?: '400' | '500'
}

type AccentProps = BaseProps & {
    fontFamily: 'accent'
    fontWeight: '600' | '800'
}

type CursiveProps = BaseProps & {
    fontFamily: 'cursive'
    fontWeight?: '400'
}

type TextProps = PrimaryProps | AccentProps | CursiveProps

const COMPONENTS = {
    hero1: 'h1',
    hero2: 'h2',
    h1: 'h3',
    h2: 'h4',
    h3: 'h5',
    readable: 'p',
    l: 'span',
    m: 'span',
    s: 'span',
    xs: 'span',
} as const

export function Text(props: TextProps) {
    const defProps = defaultProps({ fontSize: 'm' }, props)
    const [cProps, nProps] = splitProps(defProps, ['children', 'class'])

    const className = createMemo(() =>
        [textRecipe(nProps), cProps.class].filter(Boolean).join(' ')
    )

    return (
        <Dynamic component={COMPONENTS[nProps.fontSize]} class={className()}>
            {cProps.children}
        </Dynamic>
    )
}
