import {
    type Accessor,
    createContext,
    createSignal,
    type ParentProps,
} from 'solid-js'
import { useNavigate } from '@solidjs/router'
import { Hanko, type SessionDetail } from '@teamhanko/hanko-elements'

// this is so that the session cookie works in iframe (extension)
export function cookieSameSite() {
    const https = new RegExp('^https://')

    if (window.location.href.match(https)) {
        return 'none'
    }

    return 'lax'
}

export function cookieDomain() {
    return import.meta.env.VITE_COOKIE_DOMAIN
}

const hanko = new Hanko(import.meta.env.VITE_HANKO_API_URL, {
    cookieSameSite: cookieSameSite(),
    cookieDomain: cookieDomain(),
})
const [session, setSession] = createSignal<SessionDetail | null>(
    hanko.session._get()
)

// from https://github.com/teamhanko/hanko/blob/main/frontend/frontend-sdk/src/lib/Session.ts#L92
export function isValid(session: SessionDetail) {
    return !!(session.expirationSeconds > 0 && session.userID?.length)
}

export const SessionContext =
    createContext<Accessor<SessionDetail | null>>(session)

export function AuthProvider(props: ParentProps) {
    const navigate = useNavigate()

    function updateUserId() {
        setSession(hanko.session._get())
    }

    function onAuthFlowCompleted() {
        navigate('/')
    }

    hanko.onSessionCreated(updateUserId)
    hanko.onSessionExpired(updateUserId)
    hanko.onUserLoggedOut(updateUserId)
    hanko.onAuthFlowCompleted(onAuthFlowCompleted)

    return (
        <SessionContext.Provider value={session}>
            {props.children}
        </SessionContext.Provider>
    )
}
