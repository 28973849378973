type SlugifiedURL = string

export function isValidUrl(url: string): boolean {
    // Assuming the ULID part is always 26 characters long.
    // Adjust the regex if your slug part has specific constraints
    const regex = /^[a-z0-9-]+-[0-9A-HJKMNP-TV-Z]{26}$/
    return regex.test(url)
}

export function splitUrl(url: SlugifiedURL | null | undefined): string | null {
    if (!url) return null
    if (!isValidUrl(url)) throw new Error('Invalid URL')

    const lastIndex = url.lastIndexOf('-')

    return url.substring(lastIndex + 1)
}

function slugify(title: string): string {
    return title
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/(^-|-$)/g, '')
}

export function generateUrl(title: string, ulid: string): SlugifiedURL {
    const slug = slugify(title)
    return `${slug}-${ulid}`
}
