import { splitProps, JSX, createMemo } from 'solid-js'
import { defaultProps } from '@/lib/defaultProps'
import { Dynamic } from 'solid-js/web'
import { Space } from '@/designSystem/theme/space'
import { BorderWidth } from '@/designSystem/theme/borderWidth'
import { Color } from '@/designSystem/theme/color'
import {
    AlignItems,
    JustifyContent,
    FlexDirection,
    Overflow,
} from '../theme/css'
import { BorderRadius } from '../theme/borderRadius'
import { boxRecipe } from './box.css'

type Tag =
    | 'div'
    | 'section'
    | 'main'
    | 'header'
    | 'footer'
    | 'ul'
    | 'li'
    | 'nav'
type Props = {
    fill?: boolean
    alignItems?: AlignItems
    justifyContent?: JustifyContent
    flexDirection?: FlexDirection
    gap?: Space
    padding?: Space | { block?: Space; inline?: Space }
    borderColor?: Color
    background?: Color
    borderRadius?: BorderRadius
    borderWidth?: BorderWidth
    children: JSX.Element
    overflow?: Overflow
    class?: string
    tag?: Tag
}

export function Box(props: Props) {
    const propsDefault = defaultProps(
        { flexDirection: 'column', tag: 'div' },
        props
    )
    const [cProps, rProps] = splitProps(propsDefault, [
        'children',
        'tag',
        'class',
        'padding',
    ])

    const recipeProps = createMemo(() => {
        const padding = cProps.padding

        if (!padding) {
            return rProps
        }

        if (typeof padding === 'object') {
            return {
                ...rProps,
                paddingBlock: padding.block,
                paddingInline: padding.inline,
            }
        }

        return { ...rProps, padding }
    })

    const className = createMemo(() =>
        [boxRecipe(recipeProps()), cProps.class].filter(Boolean).join(' ')
    )

    return (
        <Dynamic
            component={cProps.tag}
            class={className()}
            children={cProps.children}
        />
    )
}
