import { onMount, Show } from 'solid-js'
import { register } from '@teamhanko/hanko-elements'
import { useSearchParams } from '@solidjs/router'
import ExtensionAuth from './extensionAuth'
import { cookieDomain, cookieSameSite } from '@/context/hanko'

type GlobalJsx = JSX.IntrinsicElements

declare module 'solid-js' {
    namespace JSX {
        interface IntrinsicElements {
            'hanko-auth': GlobalJsx['hanko-auth']
        }
    }
}

const EXTENSION_ORIGIN = 'extension'

export default function Auth() {
    const hankoUrl = import.meta.env.VITE_HANKO_API_URL
    const [params, _] = useSearchParams()

    onMount(() => {
        if (params.origin === EXTENSION_ORIGIN) return

        register(hankoUrl, {
            cookieSameSite: cookieSameSite(),
            cookieDomain: cookieDomain(),
        }).catch((error) => {
            console.error(error)
            // handle error
        })
    })

    return (
        <Show
            when={params.origin !== EXTENSION_ORIGIN}
            fallback={<ExtensionAuth />}
        >
            <hanko-auth />
        </Show>
    )
}
