import { detectLocale, navigatorDetector } from 'typesafe-i18n/detectors'
import { loadLocaleAsync } from '@/i18n/i18n-util.async'
import { Locales } from '@/i18n/i18n-types'

const fallbackLocale = 'en'
const availableLocales = ['en', 'es']

export function userLocale() {
    return detectLocale(
        fallbackLocale,
        availableLocales,
        navigatorDetector
    ) as Locales
}

export async function loadLocale(locale: Locales) {
    await loadLocaleAsync(locale)
}
