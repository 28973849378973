import { type IconName } from '@/designSystem/theme/icon'
import { color, type Color } from '@/designSystem/theme/color'
import { defaultProps } from '@/lib/defaultProps'
import { iconClass } from './icon.css'
import { Show } from 'solid-js'

type Props = {
    name: IconName
    class?: string
    title?: string
    color?: Color
    size?: '18' | '24' | '32'
}

export function Icon(props: Props) {
    const defProps = defaultProps({ size: '24' }, props)

    return (
        <svg
            class={[props.class, iconClass].join(' ')}
            width={defProps.size}
            height={defProps.size}
            stroke-width="2"
            stroke-linecap="round"
            fill="none"
            stroke={props.color ? color[props.color] : 'currentColor'}
        >
            <Show when={props.title}>
                {(title) => <title>{title()}</title>}
            </Show>
            <use href={`#${props.name}`} />
        </svg>
    )
}
