const huesAndShades = {
    base: {
        '0': 'oklch(99.8% 0.002 90)',
        '1000': 'oklch(18% 0.043 100)',
    },
    sand: {
        '50': 'oklch(99% 0.008 90)',
        '100': 'oklch(97% 0.014 91)',
        '200': 'oklch(92% 0.02 92)',
        '300': 'oklch(86.5% 0.025 93)',
        '400': 'oklch(79% 0.03 94)',
        '500': 'oklch(73% 0.034 95)',
        '600': 'oklch(59.5% 0.03 96)',
        '700': 'oklch(49.5% 0.03 97)',
        '800': 'oklch(40.5% 0.03 98)',
        '900': 'oklch(30% 0.02 99)',
        '950': 'oklch(22% 0.02 100)',
    },

    emerald: {
        '50': 'oklch(99.1% 0.005 160)',
        '100': 'oklch(96.6% 0.016 160)',
        '200': 'oklch(91.2% 0.042 160)',
        '300': 'oklch(85.2% 0.072 160)',
        '400': 'oklch(77.5% 0.12 160)',
        '500': 'oklch(70.5% 0.148 160)',
        '600': 'oklch(57% 0.155 160)',
        '700': 'oklch(47% 0.146 160)',
        '800': 'oklch(39% 0.121 160)',
        '900': 'oklch(28% 0.087 160)',
        '950': 'oklch(21.1% 0.049 160)',
    },

    rose: {
        '50': 'oklch(99.2% 0.0052 5)',
        '100': 'oklch(97.5% 0.016 5)',
        '200': 'oklch(92.5% 0.042 5)',
        '300': 'oklch(88% 0.072 5)',
        '400': 'oklch(81% 0.11 5)',
        '500': 'oklch(75.5% 0.148 5)',
        '600': 'oklch(62% 0.155 5)',
        '700': 'oklch(51.5% 0.146 5)',
        '800': 'oklch(42% 0.122 5)',
        '900': 'oklch(30% 0.087 5)',
        '950': 'oklch(22% 0.05 5)',
    },

    indigo: {
        '50': 'oklch(99% 0.005 263)',
        '100': 'oklch(97% 0.015515 262.4)',
        '200': 'oklch(92% 0.042 263)',
        '300': 'oklch(86.5% 0.072 264)',
        '400': 'oklch(79.5% 0.11 264)',
        '500': 'oklch(73.5% 0.147933 264)',
        '600': 'oklch(60% 0.155 264)',
        '700': 'oklch(50% 0.146 264)',
        '800': 'oklch(41% 0.121 264)',
        '900': 'oklch(30% 0.087 264)',
        '950': 'oklch(22% 0.05 264)',
    },

    orange: {
        '50': 'oklch(99% 0.005 40)',
        '100': 'oklch(97.5% 0.016 40)',
        '200': 'oklch(92.5% 0.042 40)',
        '300': 'oklch(87.5% 0.072 40)',
        '400': 'oklch(80.5% 0.11 40)',
        '500': 'oklch(75% 0.148 40)',
        '600': 'oklch(61.5% 0.155 40)',
        '700': 'oklch(51% 0.146 40)',
        '800': 'oklch(42% 0.121 40)',
        '900': 'oklch(30% 0.087 40)',
        '950': 'oklch(22% 0.05 40)',
    },

    amber: {
        '50': 'oklch(99% 0.005 88)',
        '100': 'oklch(97% 0.016 88)',
        '200': 'oklch(92% 0.042 88)',
        '300': 'oklch(86.5% 0.072 88)',
        '400': 'oklch(79.5% 0.11 88)',
        '500': 'oklch(73.5% 0.148 88)',
        '600': 'oklch(59.5% 0.14 87.9)',
        '700': 'oklch(50% 0.118 88)',
        '800': 'oklch(41% 0.097 87.9)',
        '900': 'oklch(30% 0.071 87.9)',
        '950': 'oklch(22% 0.05 88)',
    },
} as const

export const accentHues = [
    'sand',
    'emerald',
    'rose',
    'indigo',
    'orange',
    'amber',
] as const

export type Hue = keyof typeof huesAndShades
export type AccentHue = (typeof accentHues)[number]
export type BaseHue = Exclude<Hue, AccentHue>
export type ShadeTypes<H extends Hue> = keyof (typeof huesAndShades)[H]

export type BaseColor = `${BaseHue}${ShadeTypes<BaseHue>}`
export type AccentColor = `${AccentHue}${ShadeTypes<AccentHue>}`
export type Color = BaseColor | AccentColor

const flatten: { [key: string]: string } = {}

for (const hue in huesAndShades) {
    for (const shade in huesAndShades[hue as Hue]) {
        const shades = huesAndShades[hue as Hue] as any
        flatten[`${hue}${shade}`] = shades[shade] as string
    }
}
export const color = flatten as Record<Color, string>

export type Alpha = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export function colorize(hue: AccentHue, shade: ShadeTypes<AccentHue>) {
    return `${hue}${shade}` as AccentColor
}

export function shadeColor(hue: AccentHue) {
    return colorize.bind(null, hue)
}

export function contrastColor(
    hue: AccentHue,
    shade: ShadeTypes<AccentHue>,
    condition: boolean
) {
    const finalShade = condition ? String(1000 - Number(shade)) : shade

    return colorize(hue, finalShade as ShadeTypes<AccentHue>)
}

// TODO: rename this
export function mapHues(
    map: (fn: (shade: ShadeTypes<AccentHue>) => string, hue: AccentHue) => {}
) {
    return accentHues.reduce((acc, hue) => {
        acc[hue] = map((shade) => color[colorize(hue, shade)], hue)
        return acc
    }, {} as { [K in AccentHue]: {} })
}

// TODO: rename this
export function flatMapHues<T>(
    map: (fn: (shade: ShadeTypes<AccentHue>) => string, hue: AccentHue) => T[]
) {
    return accentHues.flatMap((hue) =>
        map((shade) => color[colorize(hue, shade)], hue)
    )
}

export function alpha(colorName: string, alpha: Alpha) {
    const extractedColor = colorName.match(/\((.*?)\)/)![1]
    return `oklch(${extractedColor} / ${alpha / 10})`
}
