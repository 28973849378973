import { createSignal, onMount, ParentProps, Show } from 'solid-js'
import svgSprite from '@/icons/sprite.svg?raw'
import { AuthProvider } from '@/context/hanko'
import TypesafeI18n from '@/i18n/i18n-solid'
import { setDefaultOptions } from 'date-fns'
import './rootLayout.css'
import { useRegisterSW } from 'virtual:pwa-register/solid'
import { es, enUS } from 'date-fns/locale'
import { loadLocale, userLocale } from '@/lib/i18n'

const dateLocaleMap = {
    en: enUS,
    es,
} as const

export function RootLayout(props: ParentProps) {
    const locale = userLocale()
    const [localeLoaded, setLocaleLoaded] = createSignal(false)

    useRegisterSW({
        immediate: true,
        onRegisteredSW(swUrl, r) {
            if (r) {
                setInterval(async () => {
                    if (!(!r.installing && navigator)) return
                    if ('connection' in navigator && !navigator.onLine) return

                    try {
                        const resp = await fetch(swUrl, {
                            method: 'HEAD',
                            cache: 'no-store',
                            headers: {
                                cache: 'no-store',
                                'cache-control': 'no-cache',
                            },
                        })

                        if (resp?.status === 200) await r.update()
                    } catch (_) {
                        // just ignore
                    }
                }, 120_000)
            }
        },
    })

    onMount(async () => {
        await loadLocale(locale)
        setDefaultOptions({ locale: dateLocaleMap[locale] })
        setLocaleLoaded(true)
    })

    return (
        <Show when={localeLoaded()}>
            <TypesafeI18n locale={locale}>
                <AuthProvider>
                    <div innerHTML={svgSprite} style="display: none" />
                    {props.children}
                </AuthProvider>
            </TypesafeI18n>
        </Show>
    )
}
