import { Box } from '@/designSystem/components/box'
import { Text } from '@/designSystem/components/text'
import { logoClass, ohNoClass } from './extensionAuth.css'
import { LinkButton } from '@/designSystem/components/button'

export default function ExtensionAuth() {
    return (
        <div class={ohNoClass}>
            <Text
                fontFamily="accent"
                fontWeight="800"
                fontSize="h1"
                color="emerald900"
                class={logoClass}
            >
                fika
            </Text>
            <Box
                padding="24"
                borderColor="sand300"
                borderRadius="16"
                background="base0"
                borderWidth="1"
                fill
            >
                <Box gap="16">
                    <Box>
                        <Text
                            fontFamily="accent"
                            fontWeight="600"
                            fontSize="h1"
                            color="sand950"
                        >
                            oh no!
                        </Text>
                        <Text
                            fontFamily="accent"
                            fontWeight="600"
                            fontSize="h3"
                            color="sand900"
                        >
                            you are not logged in!
                        </Text>
                    </Box>
                    <LinkButton
                        hue="emerald"
                        iconName="fika"
                        href="/"
                        target="_blank"
                    >
                        join
                    </LinkButton>
                </Box>
            </Box>
        </div>
    )
}
